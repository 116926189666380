import React from 'react';
import LoyaltyProgramDashboard from './loyapro-dashboard';
import ErrorBoundary from './ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <LoyaltyProgramDashboard />
    </ErrorBoundary>
  );
}


export default App;